<template>
  <a-table-f-data2
    ref="tableAppendix"
    :dataTable="data"
    :useQuery="false"
    :model="model"
    :searchable="false"
    :defaults="{
      filters: {},
      sort: { key: 'sort', order: 'DESC' },
      paramName: 'contractAppendix',
    }"
    @click="onClickAppendixRow($event)"
  >
    <template v-slot:item.actions="{ row }">
      <div class="d-flex" v-if="(data.status === 1 || row) && accessEdit">
        <v-btn x-small fab class="ma-0" color="red" title="Удалить" @click.stop="delRow(row.id)">
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
      </div>
    </template>
    <template v-slot:top>
      <v-fab-transition v-if="data.status !== 1 && accessEdit">
        <v-btn fab dark color="green" absolute bottom small style="bottom: 15px" left class="v-btn--example" @click="$emit('add')">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </template>
  </a-table-f-data2>
</template>

<script>
import { getAccess, popupMenu } from "@/components/mixings";
export default {
  mixins: [getAccess, popupMenu],
  components: {
    reportDialog: () => import("@/views/dialogs/objectReportDialog"),
  },
  props: {
    id: Number,
    data: { type: Array, default: () => [] },
    height: { type: Number, default: 300 },
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.contracts,

      idEditReport: 0,
      showReportDialog: false,
    };
  },
  created() {},
  watch: {
    showReportDialog() {
      if (!this.showReportDialog) {
        this.$refs.tableReports.updateData();
      }
    },
  },
  computed: {
    model() {
      return this.m.listAppendix;
    },
    accessEdit() {
      return true;
    },
    filters() {
      let f = { object_id: this.id };
      if (this.$root.profile.role > 20) {
      } else {
        f.client_visible = 1;
      }
      return f;
    },
    canAdd() {
      if (this.$root.profile.role == 20) return false;
      return true;
    },
  },
  methods: {
    onClickAppendixRow(e) {
      if (e.field.name == "actions") {
      } else {
        this.$emit("edit", e);
        //this.idEditReport = d.row.id;
        //this.showReportDialog = true;}
      }
    },

    delRow(id) {
      console.log("del", id);
      let res = this.data.filter((el) => el.id !== id);

      this.$emit("delete", res);
    },
  },
};
</script>

<style lang="sass">
.v-slide-group1__prev--disabled
  display: none !important
.v-slide-group1__next--disabled
  display: none !important
</style>
